<template>
  <div id="container">
    <full-screen-button />
    <router-link class="vue" to="/" tag="button">home</router-link>
    <button class="vue" @click="startVideo">start/pause</button>
    <button
      class="vue"
      :class="{ active: isSubtitlesEnabled }"
      @click="toggleSubtitles()"
    >
      click to toggle subtitles
    </button>
    <h1 class="subtitle" v-if="isSubtitlesEnabled">{{ currentCue }}</h1>
    <a-scene>
      <a-assets timeout="4000">
        <!-- Using the asset management system. 
          <video id="vid" muted playsinline crossorigin='anonymous' loop="true" src="360.mp4"/>
          https://github.com/aframevr/aframe/issues/1846
          <video id="vid" preload="auto" src="https://cdn.aframe.io/videos/bunny.mp4" autoplay loop crossorigin="anonymous" muted playsinline webkit-playsinline></video>

          -->
        <video
          id="vid"
          src="video2.mp4"
          loop
          crossorigin="anonymous"
          playsinline
          webkit-playsinline
        >
          <track
            id="texttrack"
            default
            src="test.vtt"
            kind="captions"
            srclang="en"
            label="English Captions"
          />
        </video>
      </a-assets>

      <!-- Using the asset management system. -->
      <a-videosphere src="#vid"> </a-videosphere>
    </a-scene>
  </div>
</template>

<script>
// @ is an alias to /src
import FullScreenButton from "../components/FullScreenButton.vue";

export default {
  name: "About",
  components: {
    FullScreenButton,
  },
  data: function () {
    return {
      scene: null,
      isSubtitlesEnabled: false,
      currentCue: "",
    };
  },
  created: function () {
  },
  mounted: function () {
    let textTrackElem = document.getElementById("texttrack");

    textTrackElem.addEventListener("cuechange", (event) => {
      let cues = event.target.track.activeCues;
      try {
        this.currentCue = cues[0].text;
      } catch (err) {
        //ha nincs több vagy épp nincs semmi szöveg
        this.currentCue = "";
      }
    });
    /*
        this.$aframe.registerComponent('play-on-window-click', {
          init: function () {
            this.onClick = this.onClick.bind(this);
          },
          play: function () {
            window.addEventListener('click', this.onClick);
          },
          pause: function () {
            window.removeEventListener('click', this.onClick);
          },
          onClick: function () {
            var video = document.getElementById('vid')
            if (!video) { return; }
            
            if(video.paused){
              video.play();
            }else{
              video.pause();
            }

          }
        });
        */
  },
  methods: {
    toggleSubtitles: function () {
      this.isSubtitlesEnabled = !this.isSubtitlesEnabled;
    },

    startVideo: function () {
      var video = document.getElementById("vid");
      if (!video) {
        return;
      }

      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    },
  },
};
</script>
<style scoped>
.vue {
  position: relative;
  z-index: 1;
}
.a-enter-vr-button {
  display: none;
}

.vue {
  z-index: 999999;
  position: relative;
}
.active {
  background: green;
}
.subtitle {
  position: fixed;
  width: 300px;
  background: #eee;
  bottom: 0px;
  right: 25%;
  left: 50%;
  margin-left: -150px;
  z-index: 999999;
}
</style>